<template>
  <div class="el_artical">
    <div class="detail mrow ">
      <div class="wrapper">
        <div class="detail-hd">
          <h1>{{result.title}}</h1>
          <p class="time">发布时间：{{result.releaseTime}}</p>
        </div>
        <template v-if="name == 2">
          <div v-html="result.contentsString"
               class="contentEm"
               style="padding:30px;line-height:35px;font-family:'微软雅黑';font-size:12px;">
          </div>
        </template>
        <template v-else>
          <div v-html="result.articleString"
               class="contentEm"
               style="padding:30px;line-height:35px;font-family:'微软雅黑';font-size:12px;">
          </div>
        </template>

        <div class="detail-btn"
             style="margin-bottom:30px;"
             v-if="toApply">
          <a @click="toRouter('apply',{cn:name + '工作站业务咨询',en:'Workstation business consulting',applyId: applyId})"
             target="_blank">业务咨询</a>
        </div>

        <!-- <div class="detail-ft">
          <a href="../investment/5.html"
             class="mbtn ful orange small left">上一篇</a>
          <a href="../investment/7.html"
             class="mbtn ful orange small right">下一篇</a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: 'el_artical',
      result: {},
      name: 1,
      toApply: false,
      applyId: '',
    }
  },
  created () {
    this.id = this.$route.query.id || ''
    this.name = this.$route.query.name || ''
    this.toApply = this.$route.query.toApply || ''
    this.applyId = this.$route.query.applyId || ''
    if (this.name == 2) {
      this.getFinancialMediaDetails();
    } else {
      this.getArticleDetails();
    }

  },
  methods: {
    toRouter (routerName, item) {
      let query = {
        ...item
      };

      this.newRouter('/' + routerName, query)
    },
    getReplaceRichText (data) {
      if (!data) return "";
      return data
        .replace(
          /<img.*?src="(.*?)".*?>/gi,
          '<img style="max-width:100%;margin-bottom: 10px;height:auto;display:block" src="$1">'
        )
        .replace(/<section/g, "<div")
        .replace(/\/section>/g, "/div>")
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"');
    },
    getArticleDetails () {
      this.api.getArticleDetails({ id: this.id }).then(res => {
        console.log(res, 9999)
        this.result = res.data.result || {};
        if (document.body.offsetWidth < 1201) {
          this.result.contentsString = this.getReplaceRichText(
            this.result.contentsString
          );
          this.result.articleString = this.getReplaceRichText(
            this.result.articleString
          );
        }

        document.title = this.result.title
        window._hmt.push(["_trackEvent", "page", "文章详情页面", this.result.title]);
      })
    },
    getFinancialMediaDetails () {
      this.api.getFinancialMediaDetails({ id: this.id }).then(res => {
        console.log(res, 9999)
        this.result = res.data.result;
        document.title = this.result.title || '文章'
        window._hmt.push(["_trackEvent", "page", "文章详情页面", document.title]);
      })
    },

  }
}
</script>
<style scoped lang="scss">
.el_artical,
.detail {
  min-height: 500px;
}
.center {
  padding: 30px;
}
@media screen and (max-width: 1201px) {
  .contentEm {
    text-indent: 20px;
  }
}
</style>
